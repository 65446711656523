/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as TermsImport } from "./routes/terms";
import { Route as AdminMarketDataImport } from "./routes/adminMarketData";
import { Route as IndexImport } from "./routes/index";

// Create/Update Routes

const TermsRoute = TermsImport.update({
	id: "/terms",
	path: "/terms",
	getParentRoute: () => rootRoute,
} as any);

const AdminMarketDataRoute = AdminMarketDataImport.update({
	id: "/adminMarketData",
	path: "/adminMarketData",
	getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
	id: "/",
	path: "/",
	getParentRoute: () => rootRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
	interface FileRoutesByPath {
		"/": {
			id: "/";
			path: "/";
			fullPath: "/";
			preLoaderRoute: typeof IndexImport;
			parentRoute: typeof rootRoute;
		};
		"/adminMarketData": {
			id: "/adminMarketData";
			path: "/adminMarketData";
			fullPath: "/adminMarketData";
			preLoaderRoute: typeof AdminMarketDataImport;
			parentRoute: typeof rootRoute;
		};
		"/terms": {
			id: "/terms";
			path: "/terms";
			fullPath: "/terms";
			preLoaderRoute: typeof TermsImport;
			parentRoute: typeof rootRoute;
		};
	}
}

// Create and export the route tree

export interface FileRoutesByFullPath {
	"/": typeof IndexRoute;
	"/adminMarketData": typeof AdminMarketDataRoute;
	"/terms": typeof TermsRoute;
}

export interface FileRoutesByTo {
	"/": typeof IndexRoute;
	"/adminMarketData": typeof AdminMarketDataRoute;
	"/terms": typeof TermsRoute;
}

export interface FileRoutesById {
	__root__: typeof rootRoute;
	"/": typeof IndexRoute;
	"/adminMarketData": typeof AdminMarketDataRoute;
	"/terms": typeof TermsRoute;
}

export interface FileRouteTypes {
	fileRoutesByFullPath: FileRoutesByFullPath;
	fullPaths: "/" | "/adminMarketData" | "/terms";
	fileRoutesByTo: FileRoutesByTo;
	to: "/" | "/adminMarketData" | "/terms";
	id: "__root__" | "/" | "/adminMarketData" | "/terms";
	fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
	IndexRoute: typeof IndexRoute;
	AdminMarketDataRoute: typeof AdminMarketDataRoute;
	TermsRoute: typeof TermsRoute;
}

const rootRouteChildren: RootRouteChildren = {
	IndexRoute: IndexRoute,
	AdminMarketDataRoute: AdminMarketDataRoute,
	TermsRoute: TermsRoute,
};

export const routeTree = rootRoute
	._addFileChildren(rootRouteChildren)
	._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/adminMarketData",
        "/terms"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/adminMarketData": {
      "filePath": "adminMarketData.tsx"
    },
    "/terms": {
      "filePath": "terms.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
